// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfvoiceconnectcallpatchapiv15 from "../../blocks/cfvoiceconnectcallpatchapiv15/src/Cfvoiceconnectcallpatchapiv15";
import Razorpayintegration6 from "../../blocks/razorpayintegration6/src/Razorpayintegration6";
import Callrouting2 from "../../blocks/callrouting2/src/Callrouting2";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Cfwhatsappnotification25 from "../../blocks/cfwhatsappnotification25/src/Cfwhatsappnotification25";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Baselinereporting from "../../blocks/baselinereporting/src/Baselinereporting";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import DesktopNotifications from "../../blocks/desktopnotifications/src/DesktopNotifications";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Cfoutboundivrapi from "../../blocks/cfoutboundivrapi/src/Cfoutboundivrapi";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Datastorage from "../../blocks/datastorage/src/Datastorage";
import Interactivevoiceresponseivr from "../../blocks/interactivevoiceresponseivr/src/Interactivevoiceresponseivr";
import Leaderboard from "../../blocks/leaderboard/src/Leaderboard";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Cfkukuapiintegration from "../../blocks/cfkukuapiintegration/src/Cfkukuapiintegration";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Freetrial2 from "../../blocks/freetrial2/src/Freetrial2";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Groups2 from "../../blocks/groups2/src/Groups2";
import Agents from "../../blocks/groups2/src/Agents";
import Cfzohointegration14 from "../../blocks/cfzohointegration14/src/Cfzohointegration14";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Texttospeechgoogle2 from "../../blocks/texttospeechgoogle2/src/Texttospeechgoogle2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Stickyagent2 from "../../blocks/stickyagent2/src/Stickyagent2";
import Timeclock from "../../blocks/timeclock/src/Timeclock";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import CustomerDialpadKeyboard from "../../blocks/customerdialpadkeyboard/src/CustomerDialpadKeyboard";
import Sorting from "../../blocks/sorting/src/Sorting";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Videos from "../../blocks/videos/src/Videos";
import ForgotPasswordMobile from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Bargeintocall2 from "../../blocks/bargeintocall2/src/Bargeintocall2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import TimeTracker from "../../blocks/timetracker/src/TimeTracker";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import UserProfileAdvance from "../../blocks/user-profile-advanced/src/UserProfileAdvance";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import AudioLibrary from "../../blocks/audiolibrary/src/AudioLibrary";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Salesforceintegration2 from "../../blocks/salesforceintegration2/src/Salesforceintegration2";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Cfcustomizedbilling2 from "../../blocks/cfcustomizedbilling2/src/Cfcustomizedbilling2";
import Cfemailverification4 from "../../blocks/cfemailverification4/src/Cfemailverification4";
import Analytics from "../../blocks/analytics/src/Analytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Leadmanagement3 from "../../blocks/leadmanagement3/src/Leadmanagement3";
import SuccessfulPage from "../../blocks/email-account-registration/src/SuccessfulPage.web";
import OTPVerificationScreen from "../../blocks/email-account-registration/src/OTPVerificationScreen.web";
import Sidebar from "../../blocks/dashboard/src/Sidebar.web";
import Header from "../../blocks/dashboard/src/Header.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import BlogListing from "../../blocks/blogpostsmanagement/src/BlogListing.web";
import BlogDetails from "../../blocks/blogpostsmanagement/src/BlogDetails.web";
import NewDashboard from "../../blocks/dashboard/src/NewDashboard.web";
import AgentAction from "../../blocks/groups2/src/AgentAction.web";
import EditAgent from "../../blocks/groups2/src/EditAgent.web";
import Holiday from "../../blocks/scheduling/src/Holiday.web";
import SettingSchedule from "../../blocks/scheduling/src/SettingSchedule.web"
import Department from "../../blocks/groups2/src/Department.web";
import Manager from "../../blocks/groups2/src/Manager.web";

import AddDepartment from "../../blocks/groups2/src/AddDepartment.web";
import EditManager from "../../blocks/groups2/src/EditManager.web";
import ManagerAction from "../../blocks/groups2/src/ManagerAction.web";
import Click2Call from "../../blocks/interactivevoiceresponseivr/src/Click2Call.web";
import AddressBook from "../../blocks/leadmanagement3/src/AddressBook.web";
import AddressBookAction from "../../blocks/leadmanagement3/src/AddressBookAction.web";
import EditContact from "../../blocks/leadmanagement3/src/EditContact.web";
import AddressBookMobile from "../../blocks/leadmanagement3/src/AddressBook";
import Campaign from "../../blocks/callrouting2/src/Campaign.web";
import CampaignMobile from "../../blocks/callrouting2/src/Campaign";
import AddInBoundIVR from "../../blocks/callrouting2/src/AddInBoundIvr.web";
import IvrFlowMobile from "../../blocks/interactivevoiceresponseivr/src/IvrFlow";
import IvrFlow from "../../blocks/interactivevoiceresponseivr/src/IvrFlow.web";
import AddIvrFlow from "../../blocks/interactivevoiceresponseivr/src/AddIvrFlow.web";
import CreateIvrMobile from "../../blocks/interactivevoiceresponseivr/src/CreateIvr";
import CreateIvr from "../../blocks/interactivevoiceresponseivr/src/CreateIvr.web";

const routeMap = {
Sidebar: {
  component: Sidebar,
path: "/Sidebar"
},
Header: {
  component: Header,
path: "/Header"
},
LandingPage: {
  component: LandingPage,
  path: "/",
  exact : true,
},
BlogListing: {
  component: BlogListing,
  path: "/BlogListing",
},
BlogDetails: {
  component: BlogDetails,
  path: "/BlogDetails/:navigationBarTitleText",
},
Dashboard: {
  component: NewDashboard,
path: "/Dashboard"
},
Manager: {
  component: Manager,
path: "/Manager"
},

ManagerAction: {
  component: ManagerAction,
  path: "/AddManager"
},
AgentAction: {
  component: AgentAction,
  path: "/AddAgent"
},
EditAgent: {
  component: EditAgent,
  path: "/EditAgent"
},
EditManager:{
  component: EditManager,
  path: "/EditManager"
},
Holiday: {
  component: Holiday,
path: "/Holiday"
},
SettingSchedule: {
  component: SettingSchedule,
path: "/Setting"
},
Click2Call: {
  component: Click2Call,
  path: "/Click2Call"
},
AddressBook: {
  component: AddressBook,
path: "/AddressBook"
},
AddressBookAction: {
  component: AddressBookAction,
path: "/AddContect"
},
EditContact: {
  component: EditContact,
path: "/EditContact"
},
Campaign: {
  component: Campaign,
path: "/Campaign"
},
AddInBoundIVR: {
  component: AddInBoundIVR,
path: "/AddInBoundIVR"
},
IvrFlow: {
  component: IvrFlow,
path: "/IvrFlow"
},
AddIvrFlow: {
  component: AddIvrFlow,
path: "/AddIvrFlow"
},
CreateIvrMobile: {
  component: CreateIvrMobile,
path: "/CreateIvrMobile"
},
CreateIvr: {
  component: CreateIvr,
path: "/CreateIvr"
},
IvrFlowMobile: {
  component: IvrFlowMobile,
path: "/IvrFlowMobile"
},
CampaignMobile: {
  component: CampaignMobile,
path: "/CampaignMobile"
},
Cfvoiceconnectcallpatchapiv15:{
 component:Cfvoiceconnectcallpatchapiv15,
path:"/Cfvoiceconnectcallpatchapiv15"},
Razorpayintegration6:{
 component:Razorpayintegration6,
path:"/Razorpayintegration6"},
Callrouting2:{
 component:Callrouting2,
path:"/Callrouting2"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Cfwhatsappnotification25:{
 component:Cfwhatsappnotification25,
path:"/Cfwhatsappnotification25"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Baselinereporting:{
 component:Baselinereporting,
path:"/Baselinereporting"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
DesktopNotifications:{
 component:DesktopNotifications,
path:"/DesktopNotifications"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
Cfoutboundivrapi:{
 component:Cfoutboundivrapi,
path:"/Cfoutboundivrapi"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Datastorage:{
 component:Datastorage,
path:"/Datastorage"},
Interactivevoiceresponseivr:{
 component:Interactivevoiceresponseivr,
path:"/Interactivevoiceresponseivr"},
Leaderboard:{
 component:Leaderboard,
path:"/Leaderboard"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Cfkukuapiintegration:{
 component:Cfkukuapiintegration,
path:"/Cfkukuapiintegration"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Freetrial2:{
 component:Freetrial2,
path:"/Freetrial2"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Groups2:{
 component:Groups2,
path:"/Groups2"},
Department:{
  component:Department,
 path:"/department"},
 AddDepartment:{
  component:AddDepartment,
 path:"/addDepartment"},
Agents: {
  component: Agents,
  path: "/Agents",
},
Cfzohointegration14:{
 component:Cfzohointegration14,
path:"/Cfzohointegration14"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Texttospeechgoogle2:{
 component:Texttospeechgoogle2,
path:"/Texttospeechgoogle2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Stickyagent2:{
 component:Stickyagent2,
path:"/Stickyagent2"},
Timeclock:{
 component:Timeclock,
path:"/Timeclock"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
CustomerDialpadKeyboard:{
 component:CustomerDialpadKeyboard,
path:"/CustomerDialpadKeyboard"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/login"},
Videos:{
 component:Videos,
path:"/Videos"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordMobile:{
  component:ForgotPasswordMobile,
 path:"/ForgotPasswordMobile"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Bargeintocall2:{
 component:Bargeintocall2,
path:"/Bargeintocall2"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
TimeTracker:{
 component:TimeTracker,
path:"/TimeTracker"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
UserProfileAdvance:{
 component:UserProfileAdvance,
path:"/UserProfileAdvance"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
AudioLibrary:{
 component:AudioLibrary,
path:"/AudioLibrary"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Salesforceintegration2:{
 component:Salesforceintegration2,
path:"/Salesforceintegration2"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/signup"},
Cfcustomizedbilling2:{
 component:Cfcustomizedbilling2,
path:"/Cfcustomizedbilling2"},
Cfemailverification4:{
 component:Cfemailverification4,
path:"/Cfemailverification4"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Leadmanagement3:{
 component:Leadmanagement3,
path:"/Leadmanagement3"},

  Home: {
component:HomeScreen,
    path: '/HomeScreen',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  OTPVerificationScreen:{
    component:OTPVerificationScreen,
   path:"/verification"},
   SuccessfulPage:{
    component:SuccessfulPage,
   path:"/success"},
   AddressBookMobile: {
    component: AddressBookMobile,
  path: "/AddressBookMobile"
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100%' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        <ToastContainer style={{ width: "400px" }} />
      </View>
    );
  }
}

export default App;
