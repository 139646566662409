export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const click2call = require("../assets/click2call.svg");
export const addressBook = require("../assets/address_book.svg");
export const addressBookDisabled = require("../assets/address_book_disabled.svg");
export const callClose = require("../assets/call_close.svg");
export const image = require("../assets/image.svg");
export const callClose1 = require("../assets/call_close_1.svg");
export const micDisable = require("../assets/mic_disable.svg");
export const micIcon = require("../assets/mic.svg");
export const profile = require("../assets/profile.svg");
export const ivrFlows = require("../assets/ivrFlows.svg");
export const clipboards = require("../assets/clipboards.svg");
export const noResultFound = require("../assets/noResultFound.svg");
export const edit = require("../assets/edit.svg");
export const copy = require("../assets/copy.svg");
export const deleteIcon = require("../assets/delete.svg");
export const download = require("../assets/download.png");
export const playIcon = require("../assets/playIcon.svg");
export const infoIcon = require("../assets/infoIcon.svg");
export const closeIcon = require("../assets/closeIcon.svg");
export const bluePluseIcon = require("../assets/bluePluseIcon.svg");
export const imageCloudUpload = require("../assets/imageCloudUpload.svg");
export const fileUploadedImage = require("../assets/fileUploadedImage.svg");
export const blackCloseIcon = require("../assets/blackCloseIcon.svg");
export const activeStateMic = require("../assets/activeStateMic.svg");
export const defaultStateMic = require("../assets/defaultStateMic.svg");
export const disableStateMic = require("../assets/disableStateMic.svg");
export const activeStateEndCall = require("../assets/activeStateEndCall.svg");