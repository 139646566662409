// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { expireTokenHandling } from "../../../components/src/Utilities";
import { getStorageData } from "../../../framework/src/Utilities";
import { FileRejection } from "react-dropzone";
import { toast } from "react-toastify";

export const configJSON = require("./config");
export const images = require("./assets");

type GetDepartmentListResponse = {
  departments: {
    data: [
      {
        attributes: IOption;
      }
    ];
  };
};

type GetMessageResponse = {
  data: [
    {
      id: number;
      message: string;
      is_active: boolean;
    }
  ];
};

type GetIvrFormResponse = {
  ivr_form: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        menu_message: {
          id: number;
          message: string;
          is_active: boolean;
        };
        greeting_message: {
          id: number;
          message: string;
          is_active: boolean;
        };
        department: {
          id: number;
          name: string;
        };
        is_menu: boolean;
        number_department_mapping: string;
        audio: {
          url: string;
          filename: string;
        };
        created_at: string;
        updated_at: string;
      };
    };
  };
};

type SuccessData = {
  message: string;
  ivr_form: {
    data: {
      id: string;
    };
  };
};

type APIErrorData = {
  errors: string[];
};

export type IOption = { id: number; name: string };

type ResponseData =
  | GetMessageResponse
  | GetDepartmentListResponse
  | GetIvrFormResponse
  | SuccessData
  | APIErrorData;
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectWelcomeGreeting: IOption | null;
  selectMenuMessage: IOption | null;
  selectSingleDepartment: IOption | null;
  isMenuNeed: boolean;
  welcomeMessageList: IOption[];
  menuMessageList: IOption[];
  departmentList: IOption[];
  menuOption: {
    number: string;
    department: IOption | null;
  }[];
  audioFiles: File[];
  audioFileName: string;
  uploadAudioFileError: string;
  voiceList: {
    voiceURI: string;
    name: string;
    lang: string;
    localService: boolean;
    default: boolean;
  }[];
  isComponentDidMount: {
    welcomeMessage: boolean;
    menuMessage: boolean;
    department: boolean;
  };
  editIvrFormId: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CreateIvrController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      selectWelcomeGreeting: null,
      selectMenuMessage: null,
      selectSingleDepartment: null,
      isMenuNeed: false,
      welcomeMessageList: [],
      menuMessageList: [],
      departmentList: [],
      menuOption: [{ number: "", department: null }],
      audioFiles: [],
      audioFileName: "",
      uploadAudioFileError: "",
      voiceList: [],
      isComponentDidMount: {
        department: false,
        menuMessage: false,
        welcomeMessage: false,
      },
      editIvrFormId: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  getWelcomeMessageApiCallId = "";
  getMenuMessageApiCallId = "";
  getDepartmentApiCallId = "";
  createIvrFormApiCallId = "";
  getIvrFormApiCallId = "";

  async componentDidMount() {
    this.getWelcomeMessageAPICall();
    this.getMenuMessageAPICall();
    this.getDepartmentAPICall();

    const loadVoices = () => {
      const availableVoices = speechSynthesis.getVoices();
      this.setState({ voiceList: availableVoices });
    };

    loadVoices();
    if (typeof speechSynthesis.onvoiceschanged !== "undefined") {
      speechSynthesis.onvoiceschanged = loadVoices;
    }
  }

  callGetIvrFormApi = () => {
    if (
      this.state.isComponentDidMount.department &&
      this.state.isComponentDidMount.menuMessage &&
      this.state.isComponentDidMount.welcomeMessage
    ) {
      this.getIvrFormAPICall();
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson);

      if (apiRequestCallId && responseJson) {
        this.handleReceiveFunction(apiRequestCallId, responseJson);
      }
    }
  }

  handleAPICall = async (
    apiEndPoint: string,
    method: string,
    body?: Object
  ) => {
    const createApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      "Content-Type": !!body ? undefined : configJSON.validationApiContentType,
      token: await getStorageData("authToken"),
    };

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    if (body) {
      createApiMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    }

    runEngine.sendMessage(createApiMsg.id, createApiMsg);

    return createApiMsg.messageId;
  };

  handleReceiveFunction = (
    apiRequestCallId: string,
    responseJson: ResponseData
  ) => {
    switch (apiRequestCallId) {
      case this.getWelcomeMessageApiCallId:
        this.receiveGetWelcomeMessageAPICall(responseJson);
        break;
      case this.getMenuMessageApiCallId:
        this.receiveGetMenuMessageAPICall(responseJson);
        break;
      case this.getDepartmentApiCallId:
        this.receiveGetDepartmentAPICall(responseJson);
        break;
      case this.createIvrFormApiCallId:
        this.receiveCreateIvrFormAPICall(responseJson);
        break;
      case this.getIvrFormApiCallId:
        this.receiveGetIvrFormAPICall(responseJson);
        break;

      default:
        break;
    }
  };

  handleWelcomeGreetingChange = (newValue: IOption | null) => {
    this.setState({
      selectWelcomeGreeting: newValue,
    });
  };

  handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    this.setState({ isMenuNeed: checked }, () =>
      this.setState({
        selectMenuMessage: null,
        selectSingleDepartment: null,
        menuOption: [{ number: "", department: null }],
      })
    );
  };

  handleSingleDepartmentChange = (newValue: IOption | null) => {
    this.setState({
      selectSingleDepartment: newValue,
    });
  };

  handleMenuMessageChange = (newValue: IOption | null) => {
    this.setState({
      selectMenuMessage: newValue,
    });
  };

  handleNumberSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    indexToUpdate: number
  ) => {
    const newMenuOption = this.state.menuOption.map((item, index) =>
      index === indexToUpdate
        ? { ...item, number: event.target.value as string }
        : item
    );
    this.setState({
      menuOption: newMenuOption,
    });
  };

  handleDepartmentSelectChange = (
    newValue: IOption | null,
    indexToUpdate: number
  ) => {
    const newMenuOption = this.state.menuOption.map((item, index) =>
      index === indexToUpdate
        ? { ...item, department: newValue as IOption }
        : item
    );
    this.setState({
      menuOption: newMenuOption,
    });
  };

  handleAddMoreDepartmentClick = () => {
    const newMenuOption = [...this.state.menuOption];
    newMenuOption.push({ number: "", department: null });
    this.setState({
      menuOption: newMenuOption,
    });
  };

  handleCloseIconClick = (indexToDelete: number) => {
    const newMenuOption = this.state.menuOption.filter(
      (_, index) => index !== indexToDelete
    );
    this.setState({
      menuOption: newMenuOption,
    });
  };

  onDrop = (files: File[], reject: FileRejection[]) => {
    if (reject && reject.length) {
      if (reject[0].errors[0].code === "too-many-files") {
        this.setState({
          uploadAudioFileError: configJSON.moreFileError,
        });
      } else if (reject[0].errors[0].code === "file-invalid-type") {
        this.setState({
          uploadAudioFileError: configJSON.invalidFileTypeError,
        });
      }
    } else {
      this.setState({
        audioFiles: files,
        uploadAudioFileError: "",
      });
    }
  };

  handleDeleteFile = () => {
    this.setState({
      audioFiles: [],
      audioFileName: "",
    });
  };

  handlePlayClick = (text: string | undefined) => {
    const utterance = new SpeechSynthesisUtterance(text);
    const voice = this.state.voiceList.find(
      (voice) => voice.name === "Google UK English Female"
    );
    if (voice) {
      utterance.voice = voice;
    }
    speechSynthesis.speak(utterance);
  };

  saveButtonDisabled = () => {
    let isDisabled =
      !this.state.selectWelcomeGreeting ||
      (!this.state.isMenuNeed && !this.state.selectSingleDepartment) ||
      (this.state.isMenuNeed && !this.state.selectMenuMessage) ||
      (this.state.isMenuNeed &&
        this.state.menuOption.some(
          (item) => item.number === "" || item.department === null
        )) ||
      (!this.state.audioFiles.length && !this.state.audioFileName);
    return isDisabled;
  };

  getWelcomeMessageAPICall = async () => {
    this.getWelcomeMessageApiCallId = await this.handleAPICall(
      `${configJSON.getMessageApiEndPoint}/get_greeting_message`,
      configJSON.validationApiMethodType
    );
  };

  receiveGetWelcomeMessageAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as GetMessageResponse;
    if (responseJsonData && responseJsonData.data) {
      this.setState(
        {
          welcomeMessageList: responseJsonData.data
            .filter((message) => message.is_active)
            .map((message) => ({
              id: message.id,
              name: message.message,
            })),
          isComponentDidMount: {
            ...this.state.isComponentDidMount,
            welcomeMessage: true,
          },
        },
        () => this.callGetIvrFormApi()
      );
    }
  };

  getMenuMessageAPICall = async () => {
    this.getMenuMessageApiCallId = await this.handleAPICall(
      `${configJSON.getMessageApiEndPoint}/get_menu_message`,
      configJSON.validationApiMethodType
    );
  };

  receiveGetMenuMessageAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as GetMessageResponse;
    if (responseJsonData && responseJsonData.data) {
      this.setState(
        {
          menuMessageList: responseJsonData.data
            .filter((message) => message.is_active)
            .map((message) => ({
              id: message.id,
              name: message.message,
            })),
          isComponentDidMount: {
            ...this.state.isComponentDidMount,
            menuMessage: true,
          },
        },
        () => this.callGetIvrFormApi()
      );
    }
  };

  getDepartmentAPICall = async () => {
    this.getDepartmentApiCallId = await this.handleAPICall(
      configJSON.getDepartmentApiEndPoint,
      configJSON.validationApiMethodType
    );
  };

  receiveGetDepartmentAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as GetDepartmentListResponse;
    if (responseJsonData && responseJsonData.departments) {
      this.setState(
        {
          departmentList: responseJsonData.departments.data.map(
            (department) => ({
              id: department.attributes.id,
              name: department.attributes.name,
            })
          ),
          isComponentDidMount: {
            ...this.state.isComponentDidMount,
            department: true,
          },
        },
        () => this.callGetIvrFormApi()
      );
    }
  };

  handleSaveClick = async () => {
    const formdata = new FormData();

    formdata.append(
      "company_ivr_form[greeting_message_id]",
      (this.state.selectWelcomeGreeting as IOption).id.toString()
    );

    formdata.append(
      "company_ivr_form[is_menu]",
      this.state.isMenuNeed.toString()
    );
    if (this.state.isMenuNeed) {
      const newMenuOption = this.state.menuOption.reduce((acc, option) => {
        if (option.department) {
          acc[option.number] = option.department.id.toString();
        }
        return acc;
      }, {} as Record<string, string>);

      formdata.append(
        "company_ivr_form[menu_message_id]",
        (this.state.selectMenuMessage as IOption).id.toString()
      );
      formdata.append(
        "company_ivr_form[number_department_mapping]",
        JSON.stringify(newMenuOption)
      );
    } else {
      formdata.append(
        "company_ivr_form[department_id]",
        (this.state.selectSingleDepartment as IOption).id.toString()
      );
    }
    if (!this.state.audioFileName) {
      formdata.append("company_ivr_form[audio]", this.state.audioFiles[0]);
    }

    let xmlString = this.state.selectWelcomeGreeting?.name;
    if (this.state.isMenuNeed) {
      const menuOptionString = this.state.menuOption.map(
        (menu) => `please press ${menu.number} for ${menu.department?.name}`
      );
      xmlString = `${this.state.selectWelcomeGreeting?.name}. ${
        this.state.selectMenuMessage?.name
      }, ${menuOptionString.join(", ")}.`;
    }

    formdata.append("company_ivr_form[xml_string]", xmlString ?? "");

    this.createIvrFormApiCallId = await this.handleAPICall(
      !!this.state.editIvrFormId
        ? `${configJSON.getMessageApiEndPoint}/${this.state.editIvrFormId}`
        : configJSON.getMessageApiEndPoint,
      !!this.state.editIvrFormId
        ? configJSON.putAPiMethod
        : configJSON.exampleAPiMethod,
      formdata
    );
  };

  receiveCreateIvrFormAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as SuccessData;
    if (responseJsonData && responseJsonData.message) {
      this.setState({
        editIvrFormId: responseJsonData.ivr_form.data.id,
      });
      toast.success(responseJsonData.message);
    }
    const responseJsonErrorData = responseJson as APIErrorData;
    if (
      responseJsonErrorData &&
      responseJsonErrorData.errors &&
      responseJsonErrorData.errors.length
    ) {
      toast.error(responseJsonErrorData.errors[0]);
    }
  };

  getIvrFormAPICall = async () => {
    this.getIvrFormApiCallId = await this.handleAPICall(
      configJSON.getMessageApiEndPoint,
      configJSON.validationApiMethodType
    );
  };

  receiveGetIvrFormAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as GetIvrFormResponse;
    if (
      responseJsonData &&
      responseJsonData.ivr_form &&
      responseJsonData.ivr_form.data
    ) {
      const apiIvrFormData = responseJsonData.ivr_form.data.attributes;
      let menuOption: {
        number: string;
        department: IOption | null;
      }[] = [{ number: "", department: null }];
      if (apiIvrFormData.is_menu) {
        const numberDepartmentData = JSON.parse(
          responseJsonData.ivr_form.data.attributes.number_department_mapping
        );
        menuOption = Object.entries(numberDepartmentData).map(
          ([number, id]) => ({
            number,
            department:
              this.state.departmentList.find(
                (department) => department.id === Number(id)
              ) ?? null,
          })
        );
      }

      this.setState({
        selectWelcomeGreeting: {
          id: apiIvrFormData.greeting_message.id,
          name: apiIvrFormData.greeting_message.message,
        },
        isMenuNeed: apiIvrFormData.is_menu,
        selectSingleDepartment: apiIvrFormData.is_menu
          ? null
          : apiIvrFormData.department,
        selectMenuMessage: apiIvrFormData.is_menu
          ? {
              id: apiIvrFormData.menu_message.id,
              name: apiIvrFormData.menu_message.message,
            }
          : null,
        menuOption,
        editIvrFormId: responseJsonData.ivr_form.data.id,
        audioFileName: apiIvrFormData.audio.filename,
      });
    }
  };
  // Customizable Area End
}
