import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
import { setStorageData, getStorageData, removeStorageData } from "framework/src/Utilities";
import { getCountryCallingCode } from 'react-phone-number-input/input'
import { expireTokenHandling } from "../../../components/src/Utilities";

export type TSignUpForm = {
  name: string;
  companyName: string;
  mobileNumber: string;
  countryCode: string;
  email: string;
};

export type TSignUpFormError = {
  name?: string;
  companyName?: string;
  mobileNumber?: string;
  countryCode?: string;
  email?: string;
};

export type TSignUpFormTouched = {
  name?: boolean;
  companyName?: boolean;
  mobileNumber?: boolean;
  countryCode?: boolean;
  email?: boolean;
};

export type IValidateFn = (data: string) => string;

export type IValidations = {
  name: IValidateFn;
  email: IValidateFn;
  mobileNumber: IValidateFn;
  countryCode: () => string;
  companyName: IValidateFn;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;

  signUpForm: TSignUpForm;
  signUpFormError: TSignUpFormError;
  signUpFormTouched: TSignUpFormTouched;
  signUpResponse: any;
  otp: any;
  time: any;
  isRunning: boolean;
  otpSuccess: boolean;
  otpSuccessResponse: any;
  otpError: boolean;
  otpErrorMessage: string;
  emailError: boolean;
  emailErrorMessage: any;
  isGoToVerification: boolean;
  mobileError: boolean;
  mobileErrorMessage: string;
  checkedAgree: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  keySignUpData: string = "";
  keySendOTP: string = "";
  keyVerifyOTP: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  inputRefs: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationSignUpDataMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",

      signUpForm: {
        name: "",
        companyName: "",
        mobileNumber: "",
        countryCode: "IN",
        email: ""
      },
      signUpFormError: {},
      signUpFormTouched: {},
      signUpResponse: {},
      otp: "",
      time: 5 * 60,
      isRunning: false,
      otpSuccess: false,
      otpSuccessResponse: {},
      otpError: false,
      otpErrorMessage: "",
      emailError: false,
      emailErrorMessage: "",
      isGoToVerification: false,
      mobileError: false,
      mobileErrorMessage: "",
      checkedAgree: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.inputRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef()
    ];
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationSignUpDataMessage)
      );
      await setStorageData("data", JSON.stringify(data));

      this.setState({
        signUpForm: {
          ...JSON.parse(await getStorageData("data"))
        }
      });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson)

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.keySignUpData:
            this.signUpFn(responseJson);
            break;

          case this.keySendOTP:
            if (responseJson.otp) {
              this.sendOTPFn(responseJson.otp.meta.token);
            }
            break;

          case this.keyVerifyOTP:
            this.verifyOTPFn(responseJson);
            break;
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  setAgree = (value: boolean) => {
    this.setState({ checkedAgree: value })
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  ////////////////////////

  async componentDidMount() {
    this.setState({ isRunning: true }, () => this.startTimer());
    this.handleSignUpRoute();
    if (
      window.localStorage.getItem("authToken") &&
      window.localStorage.getItem("isDashboard")
    ) {
      this.props.navigation.navigate("Dashboard");
    }
  }

  handleSignUpRoute = async () => {
    const pathName = window.location.pathname;
    if (pathName === "/signup") {
      removeStorageData("isVerification");
      removeStorageData("isSuccess");
    } else if (pathName === "/verification") {
      removeStorageData("isSuccess");
      const isVerification = await getStorageData("isVerification");
      if (!isVerification) {
        this.signUpNavigation();
      }
    } else if (pathName === "/success") {
      const isSuccess = await getStorageData("isSuccess");
      if (!isSuccess) {
        this.signUpNavigation();
      }
    }
  }

  startTimer = () => {
    setTimeout(this.updateTime, 1000);
  };

  updateTime = () => {
    if (this.state.isRunning && this.state.time > 0) {
      this.setState(prevState => ({
        time: prevState.time - 1
      }));
      this.startTimer();
    }
  };

  resetOTPTimer = () => {
    this.setState({
      time: 5 * 60,
      otp: "",
      otpError: false,
      otpErrorMessage: ""
    });
    this.startTimer();
    this.sendOTPApi();
  };

  handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "email" && value === " ") {
      return;
    }
    let inputValue =
      name === "mobileNumber" ? value.replace(/[a-zA-Z]/g, "") : value;
    if (name === "email") {
      inputValue = inputValue.trim()
    }
    this.setState(prevState => ({
      ...prevState,
      signUpForm: {
        ...prevState.signUpForm,
        [name]: inputValue
      },
      signUpFormTouched: {
        ...prevState.signUpFormTouched,
        [name]: false
      }
    }));
  };

  handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;

    this.setState(prevState => ({
      ...prevState,
      signUpFormTouched: {
        ...prevState.signUpFormTouched,
        [name]: true
      }
    }));

    this.validateField(name);
  };

  validateField = (name: string, customErrors: TSignUpFormError = {}) => {
    const value = this.state.signUpForm[name as keyof TSignUpForm];

    const validations: IValidations = {
      name: this.validateName,
      email: this.validateEmail,
      mobileNumber: this.validatePhone,
      countryCode: () => "",
      companyName: this.validateCompany
    };
    if (name !== "mobileNumber") {
      const fieldError: string = validations[name as keyof IValidations](value);
      customErrors[name as keyof TSignUpForm] = fieldError;
    }

    this.setState(prevState => ({
      signUpFormError: {
        ...prevState.signUpFormError,
        ...customErrors
      }
    }));
  };

  validateEmail = (value: string) => {
    if (value.trim() === "") {
      return "Email is required!";
    }
    const atIndex = value.indexOf("@");
    const dotIndex = value.lastIndexOf(".");

    if (
      atIndex <= 0 ||
      dotIndex - atIndex <= 1 ||
      dotIndex === value.length - 1
    ) {
      return "Invalid email format!";
    }

    return "";
  };

  validateCompany = (value: string) => {
    return value.trim() ? "" : "Company name is required!";
  };

  validateName = (value: string) => {
    return value.trim() ? "" : "Name is required!";
  };

  validatePhone = (value: string) => {
    const phoneNumberRegex = /^\d+$/;
    return !value.trim() || !phoneNumberRegex.test(value)
      ? "Invalid Phone number!"
      : "";
  };

  signUpApi = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    const body = {
      data: {
        type: "email_account",
        attributes: {
          company_name: this.state.signUpForm.companyName,
          email: this.state.signUpForm.email,
          contact_person: this.state.signUpForm.name,
          contact_number: this.state.signUpForm.mobileNumber && `${getCountryCallingCode(this.state.signUpForm.countryCode as any)}${this.state.signUpForm.mobileNumber}`
        }
      }
    };

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keySignUpData = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpApiEndPoint
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  };

  handleNextClick = () => {
    this.setState({ emailError: false, emailErrorMessage: "", mobileError: false, mobileErrorMessage: ""})
    let isValid = this.validateForm();
    if (isValid) {
      ///api call
      this.signUpApi();
    }
  };

  otpNavigation = () => {
    setStorageData("isVerification", "true");
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OTPVerificationScreen"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.NavigationSignUpDataMessage), {
      ...this.state.signUpForm
    });

    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  validateForm = () => {
    let isValid = true;
    const newErrors: TSignUpFormError = {};
    const newTouched: TSignUpFormTouched = {};

    Object.keys(this.state.signUpForm).forEach(fieldName => {
      this.validateField(fieldName, newErrors);
      if (newErrors[fieldName as keyof TSignUpFormError]) {
        newTouched[fieldName as keyof TSignUpFormTouched] = true
        isValid = false;
      }
    });
    this.setState({ signUpFormError: newErrors, signUpFormTouched: newTouched});
    return isValid;
  };

  handleLogin = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleInputChangeOTP = (e: any) => {
    this.setState({ otp: e, otpError: false, otpErrorMessage: "" });
  };

  signUpNavigation = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistration");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  successNavigation = () => {
    setStorageData("isSuccess", "true");
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SuccessfulPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  };

  verifyOTPFn = (responseJson: any) => {
    if (responseJson.errors) {
      let errorMsg = "";
      responseJson.errors.map((key: any) => {
        errorMsg = key;
      });
      errorMsg = Object.values(errorMsg)[0];
      this.setState({
        otpError: true,
        otpErrorMessage: errorMsg
      });
    } else {
      this.setState({
        otpSuccessResponse: responseJson.data
      });
      setStorageData("name", responseJson.data.attributes.contact_person);
      setStorageData("userId", responseJson.data.id);
      setStorageData("role", "company");
      setStorageData("authToken", responseJson.meta.token);
      this.successNavigation();
    }
  };

  otpConfirmationApi = async () => {
    const otpToken = await getStorageData("OTPToken");

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: otpToken
    };

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keyVerifyOTP = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.otpVerificationApiEndPoint}${this.state.otp}`
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  };

  sendOTPApi = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    const body = {
      data: {
        attributes: {
          email: this.state.signUpForm.email,
          full_phone_number: this.state.signUpForm.mobileNumber && `${getCountryCallingCode(this.state.signUpForm.countryCode as any)}${this.state.signUpForm.mobileNumber}`
        }
      }
    };

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keySendOTP = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOtpApiEndPoint
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  };

  sendOTPFn = (token: string) => {
    setStorageData("OTPToken", token);
    if (this.state.isGoToVerification) {
        this.otpNavigation();
    }
  }

  handleBackToHomePage = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  };

  signUpFn = (responseJson: any) => {
    if (responseJson.errors) {
      let key = responseJson.errors[0];
      if (key && key.phone_number) {
        this.setState({
          mobileError: true,
          mobileErrorMessage: Object.values(key)[0] as string
        });
      } else {
        this.setState({
          emailError: true,
          emailErrorMessage: Object.values(key)[0]
        });
      }
    } else {
      this.setState({
        signUpResponse: responseJson.data,
        isGoToVerification: true
      }, () => {
        this.sendOTPApi()
      });
    }
  };

  handleCountryCodeChange = (event: React.ChangeEvent<{ name?: string; value: string | unknown }>) => {
    this.setState((prevState) => ({
      ...prevState,
      signUpForm: {
        ...prevState.signUpForm,
        countryCode: event.target.value as string,
      },
    }));
  }
  
  // Customizable Area End
}
