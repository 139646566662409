import React, { useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Link } from "react-router-dom";
import {
  Typography,
  IconButton,
  AppBar,
  Toolbar,
  Box,
  MenuItem,
  Menu as MenuComponent,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { RoleType } from "../../../components/src/Utilities";
const images = require("../src/assets");

const drawerWidth = 214;

const useStyles = makeStyles((theme) => ({
  drawerWeb: {
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerWidth,
    },
    "& .MuiDrawer-paperAnchorLeft": {
      right: "auto",
      left: "auto",
    },
    "& .MuiList-root": {
      overflow: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
      "& .MuiPaper-root.MuiDrawer-paper": {
        background: "#FFFFFF",
        boxShadow: "4px 0px 32px 0px #0000000F",
        border: "none",
        position: "relative",
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  drawerMobile: {
    justifyContent: "center",
    width: drawerWidth,
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerWidth,
    },
    "& .MuiDrawer-paperAnchorLeft": {
      left: 0,
      right: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      "& .MuiPaper-root.MuiDrawer-paper": {
        background: "#FFFFFF",
      },
      "& .MuiDrawer-paper .MuiList-root": {
        overflow: "auto",
        scrollbarWidth: "none",
        padding: "40px 0",
      },
    },
  },
  list: {
    "& .icon": {
      width: "24px",
      height: "24px",
    },
    "& .activeItem": {
      "& .MuiTypography-root": {
        color: "#007ACC",
        fontWeight: 700,
      },
    },
    "& .disabled": {
      pointerEvents: "none",
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
  listItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#281D19",
    fontWeight: 400,
    padding: "12px",
    width: "100%",
    textDecoration: "none",
    gap: "6px",
    "&:hover": {
      background: "#CCEBFF",
      "& .MuiTypography-root": {
        color: "#003D66",
        fontWeight: 400,
      },
    },
    "&.hoverItem": {
      background: "#CCEBFF",
      "& .MuiTypography-root": {
        color: "#003D66",
        fontWeight: 400,
      },
    },
  },
  icon: {
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2px",
    minWidth: "auto",
  },
  menuButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  menuPaper: {
    margin: "16px",
    boxShadow: "4px 0px 32px 0px #0000000F",
  },
  menuList: {
    padding: "12px 0px",
    "& .MuiMenuItem-root": {
      padding: "12px",
      background: "transparent",
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontFamily: "Poppins",
        lineHeight: "22px",
        fontWeight: 400,
        color: "#281D19",
        textDecoration: "none",
      },
    },
  },
}));

export type TMenuItem =
  | "dashboard"
  | "manageDept"
  | "manager"
  | "agent"
  | "holiday"
  | "disposition"
  | "transferList"
  | "dialOut"
  | "location"
  | "setting"
  | "click2Call"
  | "addressBook"
  | "campaign"
  | "createIvr"
  | "ivrFlow";
interface IPropSidebar {
  menuItem: TMenuItem;
  userRole: string;
}

export default function Sidebar({ menuItem, userRole }: IPropSidebar) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOnMouseEnter = (
    event: React.MouseEvent<HTMLDivElement>,
    selectedId: string
  ) => {
    setSelectedImageIndex(selectedId);
    setAnchorEl(event.currentTarget);
  };

  const handleOnMouseLeave = () => {
    setSelectedImageIndex("");
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const links = [
    {
      id: "dashboard",
      text: "Dashboard",
      icon: images.dashboardIcon,
      selectedIcon: images.dashboardIconSelected,
      hoveredIcon: images.dashboardIconSelected,
      link: "/dashboard",
      role: RoleType.All,
    },
    {
      id: "manageDept",
      text: "Manage Dept",
      icon: images.manageDeptIcon,
      selectedIcon: images.manageDeptIcon,
      hoveredIcon: images.manageDeptIcon,
      link: "/department",
      role: RoleType.Company,
    },
    {
      id: "manager",
      text: "Managers",
      icon: images.managersIcon,
      selectedIcon: images.managersIcon,
      hoveredIcon: images.managersIcon,
      link: "/manager",
      role: RoleType.Company,
    },
    {
      id: "agent",
      text: "Agents",
      icon: images.agentsIcon,
      selectedIcon: images.agentsIcon,
      hoveredIcon: images.agentsIconHovered,
      role: RoleType.Company,
      subMenu: [
        { id: "onlineAgents", text: "Online Agents", link: "/agents" },
        { id: "offlineAgents", text: "Offline Agents", link: "/agents" },
        {
          id: "numberManagement",
          text: "Number Management",
          isDisabled: true,
        },
        { id: "champaign", text: "Champaign", link: "/campaign" },
      ],
    },
    {
      id: "holiday",
      text: "Holiday",
      icon: images.businessScheduleIcon,
      selectedIcon: images.businessScheduleIcon,
      hoveredIcon: images.businessScheduleIcon,
      link: "/holiday",
      role: RoleType.Company,
    },
    {
      id: "businessSchedule",
      text: "Business Schedule",
      icon: images.businessScheduleIcon,
      selectedIcon: images.businessScheduleIcon,
      hoveredIcon: images.businessScheduleIcon,
      link: "/",
      role: RoleType.Company,
      isDisabled: true,
    },
    {
      id: "agentTemplate",
      text: "Agent Template",
      icon: images.agentTemplateIcon,
      selectedIcon: images.agentTemplateIcon,
      hoveredIcon: images.agentTemplateIcon,
      link: "/",
      role: RoleType.Company,
      isDisabled: true,
    },
    {
      id: "onlineAgents",
      text: "Online Agents",
      icon: images.onlineAgentsIcon,
      selectedIcon: images.onlineAgentsIcon,
      hoveredIcon: images.onlineAgentsIcon,
      link: "/",
      role: RoleType.Company,
      isDisabled: true,
    },
    {
      id: "offlineAgents",
      text: "Offline Agents",
      icon: images.offlineAgentsIcon,
      selectedIcon: images.offlineAgentsIcon,
      hoveredIcon: images.offlineAgentsIcon,
      link: "/",
      role: RoleType.Company,
      isDisabled: true,
    },
    {
      id: "numberManagement",
      text: "Number Management",
      icon: images.numberManagementIcon,
      selectedIcon: images.numberManagementIcon,
      hoveredIcon: images.numberManagementIcon,
      link: "/",
      role: RoleType.Company,
      isDisabled: true,
    },
    {
      id: "click2Call",
      text: "Click 2 Call",
      icon: images.dashboardIcon,
      selectedIcon: images.dashboardIconSelected,
      hoveredIcon: images.dashboardIconSelected,
      link: "/click2call",
      role: RoleType.Agent,
    },
    {
      id: "addressBook",
      text: "Address Book",
      icon: images.dashboardIcon,
      selectedIcon: images.dashboardIconSelected,
      hoveredIcon: images.dashboardIconSelected,
      link: "/addressBook",
      role: RoleType.Agent,
    },
    {
      id: "callCalendar",
      text: "Call Calendar",
      icon: images.dashboardIcon,
      selectedIcon: images.dashboardIconSelected,
      hoveredIcon: images.dashboardIconSelected,
      link: "/",
      role: RoleType.Agent,
      isDisabled: true,
    },
    {
      id: "notification",
      text: "Notification",
      icon: images.dashboardIcon,
      selectedIcon: images.dashboardIconSelected,
      hoveredIcon: images.dashboardIconSelected,
      link: "/",
      role: RoleType.Agent,
      isDisabled: true,
    },
    {
      id: "accountProfile",
      text: "Account profile",
      icon: images.dashboardIcon,
      selectedIcon: images.dashboardIconSelected,
      hoveredIcon: images.dashboardIconSelected,
      link: "/",
      role: RoleType.Agent,
      isDisabled: true,
    },
    {
      id: "campaign",
      text: "Champaign",
      icon: images.champaignIcon,
      selectedIcon: images.champaignIcon,
      hoveredIcon: images.champaignIcon,
      link: "/campaign",
      role: RoleType.Company,
    },
    {
      id: "createIvr",
      text: "Create IVR",
      icon: images.champaignIcon,
      selectedIcon: images.champaignIcon,
      hoveredIcon: images.champaignIcon,
      link: "/createIvr",
      role: RoleType.Company,
    },
    {
      id: "ivrFlow",
      text: "Ivr Flows",
      icon: images.dashboardIcon,
      selectedIcon: images.dashboardIconSelected,
      hoveredIcon: images.dashboardIconSelected,
      link: "/ivrFlow",
      role: RoleType.Company,
    },
    {
      id: "setting",
      text: "Settings",
      icon: images.dashboardIcon,
      selectedIcon: images.dashboardIconSelected,
      hoveredIcon: images.dashboardIconSelected,
      link: "/setting",
      role: RoleType.Company,
    },
    {
      id: "agent_setting",
      text: "Settings",
      icon: images.dashboardIcon,
      selectedIcon: images.dashboardIconSelected,
      hoveredIcon: images.dashboardIconSelected,
      link: "/setting",
      role: RoleType.Agent,
      isDisabled: true,
    },
  ];

  const getImage = (item: {
    id: string;
    text: string;
    icon: string;
    selectedIcon: string;
    hoveredIcon: string;
    link?: string;
    role: string;
    subMenu?: object;
    isDisabled?: boolean;
  }) => {
    let returnIcon;
    if (selectedImageIndex === item.id) {
      returnIcon = item.hoveredIcon;
    } else {
      returnIcon = menuItem === item.id ? item.selectedIcon : item.icon;
    }
    return returnIcon;
  };

  const drawer = (
    <List className={classes.list}>
      {links
        .filter((item) => item.role.includes(userRole))
        .map((item) => (
          <ListItem
            data-test-id="listItem"
            key={item.id}
            onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) =>
              handleOnMouseEnter(event, item.id)
            }
            onMouseLeave={handleOnMouseLeave}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              !(item.subMenu && !!anchorEl && selectedImageIndex === item.id)
                ? handleOnMouseEnter(event, item.id)
                : handleOnMouseLeave();
            }}
            button
            disabled={item.isDisabled}
            style={{ padding: "0px", background: "none" }}
          >
            <Typography
              component={Link}
              to={item.link || "#"}
              className={`${classes.listItem} ${
                menuItem === item.id ? "activeItem" : ""
              } ${selectedImageIndex === item.id ? "hoverItem" : ""}`}
            >
              <ListItemIcon className={classes.icon}>
                <img src={getImage(item)} className="icon" />
              </ListItemIcon>
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  lineHeight: "22px",
                }}
              >
                {item.text}
              </Typography>
              {item.subMenu && !!anchorEl && selectedImageIndex === item.id && (
                <MenuComponent
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleOnMouseLeave}
                  classes={{ paper: classes.menuPaper, list: classes.menuList }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {item.subMenu.map((subItem) => (
                    <MenuItem key={subItem.id} disabled={subItem.isDisabled}>
                      <Typography component={Link} to={subItem.link || "#"}>
                        {subItem.text}
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuComponent>
              )}
            </Typography>
          </ListItem>
        ))}
    </List>
  );

  return (
    <SidebarContainer data-test-id="sidebarContainer">
      <AppBarCustom
        data-test-id="appBarCustom"
        position="static"
        color="transparent"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            data-test-id="btnOpenDrawer"
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBarCustom>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        data-test-id="drawerMobile"
        ModalProps={{
          keepMounted: true,
        }}
        className={classes.drawerMobile}
      >
        {drawer}
      </Drawer>
      <Drawer
        id="drawer"
        className={classes.drawerWeb}
        variant="permanent"
        open
      >
        {drawer}
      </Drawer>
    </SidebarContainer>
  );
}

export const AppBarCustom = styled(AppBar)(({ theme }) => ({
  display: "none",
  width: "48px",
  height: "48px",
  justifyContent: "center",
  boxShadow: "none",
  "& .MuiToolbar-root": {
    backgroundColor: "transparent",
    padding: 0,
  },
  "& .MuiToolbar-regular": {
    minHeight: "unset",
  },
  [theme.breakpoints.down("xs")]: {
    display: "block",
  },
}));

export const SidebarContainer = styled(Box)(({ theme }) => ({
  "& #drawer": {
    "& .MuiList-root": {
      overflow: "auto",
      scrollbarWidth: "none",
      padding: "16px 0",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
  },
}));
