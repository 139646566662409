// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  styled,
  TextField,
  IconButton,
  Switch,
  SwitchProps,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import CreateIvrController, {
  Props,
  images,
  configJSON,
} from "./CreateIvrController";
import Layout from "../../../components/src/Layout.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Dropzone from "react-dropzone";
// Customizable Area End

export default class CreateIvr extends CreateIvrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <Layout menuItem="createIvr" navigation={this.props.navigation}>
        <MainContainer data-test-id="createIvrComponent">
          <Box className="container">
            <Box className="labelAndInputBox">
              <Typography className="inputLabel maxWidth350px">
                {configJSON.welcomeGreeting}
              </Typography>
              <Box className="selectAndPlayBox">
                <Autocomplete
                  data-test-id="selectWelcomeGreeting"
                  id="selectWelcomeGreeting"
                  className="autoCompleteStyle"
                  options={this.state.welcomeMessageList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="selectInput"
                    />
                  )}
                  value={this.state.selectWelcomeGreeting}
                  onChange={(_, newValue) =>
                    this.handleWelcomeGreetingChange(newValue)
                  }
                />
                <IconButton
                  data-test-id="selectWelcomeGreetingPlayIcon"
                  className="playIconButton"
                  onClick={() =>
                    this.handlePlayClick(this.state.selectWelcomeGreeting?.name)
                  }
                  disabled={!this.state.selectWelcomeGreeting?.id}
                >
                  <img src={images.playIcon} />
                </IconButton>
              </Box>
            </Box>
            <Box className="labelAndInputBox">
              <Typography className="inputLabel maxWidth350px">
                {configJSON.needAMenu}
                <img src={images.infoIcon} />
              </Typography>
              <Box className="switchAndText">
                <SwitchCustom
                  data-test-id="menuNeed"
                  id="menuNeed"
                  name="menuNeed"
                  checked={this.state.isMenuNeed}
                  onChange={this.handleSwitchChange}
                />
                <Typography className="inputLabel">
                  {this.state.isMenuNeed
                    ? configJSON.yesText
                    : configJSON.noText}
                </Typography>
              </Box>
            </Box>
            {this.state.isMenuNeed ? (
              <>
                <Box className="labelAndInputBox">
                  <Typography className="inputLabel maxWidth350px">
                    {configJSON.menuMessage}
                  </Typography>
                  <Box className="selectAndPlayBox">
                    <Autocomplete
                      data-test-id="selectMenuMessage"
                      id="selectMenuMessage"
                      className="autoCompleteStyle"
                      options={this.state.menuMessageList}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          className="selectInput"
                        />
                      )}
                      value={this.state.selectMenuMessage}
                      onChange={(_, newValue) =>
                        this.handleMenuMessageChange(newValue)
                      }
                    />
                    <IconButton
                      data-test-id="selectMenuMessagePlayIcon"
                      className="playIconButton"
                      onClick={() =>
                        this.handlePlayClick(this.state.selectMenuMessage?.name)
                      }
                      disabled={!this.state.selectMenuMessage?.id}
                    >
                      <img src={images.playIcon} />
                    </IconButton>
                  </Box>
                </Box>
                {this.state.menuOption.map((option, optionIndex) => (
                  <Box
                    className="labelAndInputBox"
                    key={`${optionIndex}_${option.department}_${option.number}`}
                  >
                    <Box className="numberLabelBox maxWidth350px">
                      <Typography className="inputLabel">
                        {configJSON.press}
                      </Typography>
                      <Select
                        data-test-id="selectNumber"
                        className="SelectCustom"
                        id="selectNumber"
                        name="selectNumber"
                        value={option.number}
                        onChange={(event) =>
                          this.handleNumberSelectChange(event, optionIndex)
                        }
                      >
                        {Array.from({ length: 10 }, (_, index) => index).map(
                          (index) => (
                            <MenuItem key={index} value={index}>
                              {index}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      <Typography className="inputLabel">
                        {configJSON.forText}
                      </Typography>
                    </Box>
                    <Box className="departmentSelectBox">
                      <Box className="maxWidth280px">
                        <Autocomplete
                          data-test-id="selectDepartment"
                          id="selectDepartment"
                          className="autoCompleteStyle"
                          options={this.state.departmentList}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              className="selectInput"
                            />
                          )}
                          value={option.department}
                          onChange={(_, newValue) => {
                            this.handleDepartmentSelectChange(
                              newValue,
                              optionIndex
                            );
                          }}
                        />
                      </Box>
                      {!!optionIndex && (
                        <IconButton
                          className="playIconButton"
                          data-test-id="closeIcon"
                          onClick={() => this.handleCloseIconClick(optionIndex)}
                        >
                          <img src={images.closeIcon} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                ))}
                <Box className="labelAndInputBox">
                  <Box className="maxWidth350px" />
                  <Box className="maxWidth280px">
                    <Button
                      data-test-id="add-more-department"
                      variant="contained"
                      className="blueButton maxWidth280px"
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      onClick={this.handleAddMoreDepartmentClick}
                    >
                      {configJSON.addMoreDepartment}
                    </Button>
                  </Box>
                </Box>
              </>
            ) : (
              <Box className="labelAndInputBox">
                <Box className="maxWidth350px" />
                <Box className="maxWidth280px">
                  <Autocomplete
                    data-test-id="selectSingleDepartment"
                    id="selectSingleDepartment"
                    className="autoCompleteStyle"
                    options={this.state.departmentList}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className="selectInput"
                      />
                    )}
                    value={this.state.selectSingleDepartment}
                    onChange={(_, newValue) => {
                      this.handleSingleDepartmentChange(newValue);
                    }}
                  />
                </Box>
              </Box>
            )}
            <Box className="labelAndInputBox">
              <Typography className="inputLabel maxWidth350px">
                {configJSON.uploadAudioFile}
                <img src={images.infoIcon} />
              </Typography>
              <Box className="maxWidth407px">
                {this.state.audioFiles.length || this.state.audioFileName ? (
                  <Box
                    className="projectFiles__item"
                    data-test-id="uploaded-file"
                  >
                    <img src={images.fileUploadedImage} />
                    <Box className="projectFiles__detail">
                      <Box className="projectFiles__info">
                        <Typography component="h6">
                          {this.state.audioFileName ||
                            this.state.audioFiles[0].name}
                        </Typography>
                        {!this.state.audioFileName && (
                          <Typography component="p">
                            {(this.state.audioFiles[0].size / 1024).toFixed(2)}{" "}
                            {configJSON.kbText}
                          </Typography>
                        )}
                      </Box>
                      <IconButton
                        className="projectFiles__action"
                        onClick={this.handleDeleteFile}
                        data-test-id="btnDeleteFile"
                      >
                        <img
                          src={images.blackCloseIcon}
                          width={32}
                          height={32}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Dropzone
                      onDrop={this.onDrop}
                      accept={{
                        "audio/mpeg": [".mp3"],
                        "audio/mp4": [".mp4"],
                      }}
                      maxFiles={1}
                      data-test-id="dropzone"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Box {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <img
                            src={images.imageCloudUpload}
                            width={48}
                            height={48}
                          />
                          <Box className="uploadTextBox">
                            <Box textAlign="center">
                              <Typography component="h3">
                                {configJSON.uploadMedia}
                              </Typography>
                              <Typography component="p">
                                {configJSON.dragAndDropFiles}
                              </Typography>
                            </Box>
                            <Box className="upload__text">
                              <img
                                src={images.bluePluseIcon}
                                width={20}
                                height={20}
                              />
                              <Typography component="span">
                                {configJSON.browseFileToUpload}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Dropzone>
                    {this.state.uploadAudioFileError && (
                      <div
                        className="upload-error-text"
                        data-test-id="file-upload-error"
                      >
                        {this.state.uploadAudioFileError}
                      </div>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Button
              data-test-id="saveButton"
              variant="contained"
              className="blueButton saveButton"
              disabled={this.saveButtonDisabled()}
              onClick={this.handleSaveClick}
            >
              {configJSON.saveButton}
            </Button>
          </Box>
        </MainContainer>
      </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainContainer = styled(Box)(() => ({
  gap: "40px",
  display: "flex",
  flexDirection: "column",
  "& .maxWidth407px": {
    maxWidth: "407px",
    width: "100%",
  },
  "& .maxWidth350px": {
    maxWidth: "350px",
    width: "100%",
    "@media (max-width:959px)": {
      maxWidth: "300px",
    },
  },
  "& .maxWidth280px": {
    maxWidth: "280px",
    width: "100%",
  },
  "& .container": {
    background: "#FFFFFF",
    padding: "40px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "33px",
  },
  "& .labelAndInputBox": {
    display: "flex",
    alignItems: "flex-start",
    gap: "16px",
    "@media (max-width:600px)": {
      flexDirection: "column",
    },
  },
  "& .inputLabel": {
    color: "#334155",
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "26px",
    display: "flex",
    gap: "2px",
  },
  "& .selectAndPlayBox": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "14px",
  },
  "& .autoCompleteStyle": {
    width: "100%",
    maxWidth: "407px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
    },
  },
  "& .selectInput": {
    "& .MuiInputBase-input": {
      color: "#0F172A",
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: 400,
      lineHeight: "24px",
    },
  },
  "& .playIconButton": {
    padding: "0px",
  },
  "& .switchAndText": {
    display: "flex",
    gap: "8px",
  },
  "& .departmentSelectBox": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    width: "100%",
  },
  "& .numberLabelBox": {
    display: "flex",
    alignItems: "center",
    gap: "24px",
  },
  "& .SelectCustom": {
    width: "100%",
    maxWidth: "155px",
    "&:before": {
      content: "none",
    },
    "&:after": {
      content: "none",
    },
    "& .MuiInputBase-input": {
      height: "34px",
      borderRadius: "8px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      border: "1px solid",
      borderColor: "#CBD5E1",
      width: "100%",
      padding: "10px 12px",
      display: "flex",
      alignItems: "center",
      color: "#181818",
      fontSize: "18px",
      fontFamily: "Poppins",
      fontWeight: 700,
      lineHeight: "26px",
    },
    "& .MuiSelect-icon": {
      padding: "0 7px",
      right: 1,
      background: "white",
    },
  },
  "& .blueButton": {
    color: "white",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    backgroundColor: "#0099FF",
    borderColor: "#0099FF",
    borderRadius: "8px",
    padding: "10px 16px",
    "&:disabled": {
      color: "#64748B",
      backgroundColor: "#F1F5F9",
      borderColor: "#F1F5F9",
    },
  },
  "& .saveButton": {
    width: "fit-content",
  },
  "& .dropzone": {
    border: "2px dashed #CBD5E1",
    borderRadius: "16px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px",
    "& .uploadTextBox": {
      padding: "12.5px 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "8px",
    },
    "& h3": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#0F172A",
      marginBottom: "4px",
    },
    "& p": {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#475569",
    },
    "& .upload__text": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    "& .upload__text span": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#0099FF",
    },
  },
  "& .upload-error-text": {
    color: "#DC2626",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: "16px",
  },
  "& .projectFiles__item": {
    padding: "8px",
    display: "flex",
    alignItems: "center",
    gap: "16px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    borderRadius: "8px",
    position: "relative",
    "& .projectFiles__detail": {
      flex: "1",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
      "& h6": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#0F172A",
      },
      "& p": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#64748B",
      },
    },
    "& .projectFiles__action": { padding: "0px" },
  },
}));

const SwitchCustom = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 28,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        background: "#0099FF",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#FFFFFF",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 24,
    height: 24,
  },
  "& .MuiSwitch-track": {
    borderRadius: 40,
    backgroundColor: "#E2E8F0",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
// Customizable Area End
