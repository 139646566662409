Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "interactivevoiceresponseivr";
exports.labelBodyText = "interactivevoiceresponseivr Body";
exports.deleteAPiMethod = "DELETE";
exports.patchAPiMethod = "PATCH";
exports.putAPiMethod = "PUT";

exports.btnExampleTitle = "CLICK ME";

exports.click2call = "Click 2 Call";
exports.fromNumber = "From Number";
exports.toNumber = "To Number";
exports.call = "Call";
exports.kevinKare = "Kevin Kare";
exports.ringing = "Ringing";
exports.number = "+91 98765 43210";
exports.searchGroup = "Search Group";
exports.searchContact = "Search Contact";
exports.open = "Open";
exports.done = "Done";
exports.recentCalls = "Recent Calls";
exports.rahulThokur = "Rahul Thakur";
exports.timing = "15:30 PM";
exports.dateText = "Sunday, 24 November";
exports.callNotes = "Call Notes";
exports.depositionHistory = "Deposition History";
exports.depositionText = "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod";
exports.getDIDNumberApiEndPoint = "bx_block_interactivevoiceresponseivr/did_numbers";
exports.getGroupListApiEndPoint = "bx_block_leadmanagement3/contact_groups";
exports.getContactListApiEndPoint = "bx_block_leadmanagement3/contacts";
exports.createCallApiEndPoint = "bx_block_cfkukuapiintegration/kookoo/outbound_call";
exports.kookooApiEndPoint = "bx_block_cfkukuapiintegration/kookoo";
exports.webSocketUrl = "wss://callifony-356545-ruby.b356545.dev.eastus.az.svc.builder.cafe/cable";

exports.ivrFlow = "IVR Flows";
exports.createIvrFlows = "Create IVR Flows";
exports.ivrFlowDataMainText = "No Data Available";
exports.ivrFlowDataSubText = "There is no data show you right now.";
exports.flowName = "Flow Name";
exports.selectFlowType = "Select Flow Type";
exports.flowType = "Flow Type";
exports.custom = "Custom";
exports.transfer = "Transfer";
exports.appURL = "App URL";
exports.save = "Save";
exports.ivrFlowApiEndPoint = "bx_block_interactivevoiceresponseivr/ivr_forms";
exports.nameErrorMessage = "The Flow name entered is already in use."
exports.noResultFound = "No Result found";
exports.noIvrFlowMatched = "No IVR Flow matched your Criteria. Try Searching for something else.";
exports.ivrFlowId = "IVR Flow ID";
exports.createdDate = "Created Date";
exports.lastModifiedDate = "Last Modified Date";
exports.design = "Design";
exports.delete = "Delete";
exports.downloadIvrFlow = "Download IVR Flows:";
exports.csv = "CSV";
exports.xlsx = "XLSX";
exports.download = "Download";

exports.welcomeGreeting = "Welcome Greeting";
exports.needAMenu = "Need a Menu";
exports.yesText = "Yes";
exports.noText = "No";
exports.menuMessage = "Menu Message";
exports.press = "Press";
exports.forText = "for";
exports.addMoreDepartment = "Add More Department";
exports.uploadAudioFile = "Upload Audio File";
exports.uploadMedia = "Upload media";
exports.dragAndDropFiles = "Drag and drop the files here";
exports.browseFileToUpload = "Browse file to upload";
exports.moreFileError = "Please select one file at a time for upload."
exports.invalidFileTypeError = "The selected file does not match the allowed file types of mp3."
exports.kbText = "KB";
exports.saveButton = "Save";
exports.getDepartmentApiEndPoint = "/bx_block_account_groups/departments";
exports.getMessageApiEndPoint = "bx_block_interactivevoiceresponseivr/company_ivr_forms";
exports.ivrFormApiContentType = "multipart/form-data";
// Customizable Area End