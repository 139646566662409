// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  Button,
  styled,
  IconButton,
  InputBase,
  InputAdornment,
} from "@material-ui/core";
import CallRoundedIcon from "@material-ui/icons/CallRounded";
import Click2CallController, {
  Props,
  images,
  configJSON,
} from "./Click2CallController";
import Layout from "../../../components/src/Layout.web";
import CustomSelectMenu from "../../../components/src/CustomSelectMenu.web";
import CustomDialog from "../../../components/src/CustomDialog.web";
// Customizable Area End

export default class Click2Call extends Click2CallController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <Layout menuItem="click2Call" navigation={this.props.navigation}>
        <MainContainer data-test-id="click2CallComponent">
          <Box className="leftSideBox">
            <Box className="header">
              <img src={images.click2call} width={64} height={48} />
              <Typography className="headerTitle">
                {configJSON.click2call}
              </Typography>
            </Box>
            <Box className="formInput">
              <Typography className="inputLabel">
                {configJSON.fromNumber}
              </Typography>
              <CustomSelectMenu
                data-test-id="fromNumberSelect"
                list={this.state.didNumberList.map((number) => ({
                  id: number.number,
                  label: number.number,
                }))}
                value={this.state.fromNumber}
                name="fromNumber"
                onChange={this.handleFromValue}
                disabled={
                  this.state.didNumberList.length < 2 ||
                  !!this.state.successMessage
                }
              />
            </Box>
            <Box>
              <Box className="inputWithAddressBook">
                <Box className="formInput">
                  <Typography className="inputLabel">
                    {configJSON.toNumber}
                  </Typography>
                  <InputBase
                    data-test-id="toNumberInput"
                    className="inputBox"
                    type="text"
                    value={this.state.toNumber}
                    onChange={this.handleToValue}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        style={{ zIndex: 1, marginRight: 0 }}
                      >
                        <CallRoundedIcon className="callIcon" />
                      </InputAdornment>
                    }
                    disabled={!!this.state.successMessage}
                  />
                </Box>
                <IconButton
                  data-test-id="addressBookButton"
                  className="addressBookIcon"
                  onClick={this.onClickToOpenContactDialog}
                  disabled={!!this.state.successMessage}
                >
                  <img src={images.addressBook} />
                </IconButton>
              </Box>
              {!!this.state.errorMessage && (
                <Typography
                  color="error"
                  className="mt-24"
                  data-test-id="error-message"
                >
                  {this.state.errorMessage}
                </Typography>
              )}
            </Box>
            {!this.state.successMessage ? (
              <Button
                data-test-id="callButton"
                className="button callButton"
                disabled={
                  !this.state.fromNumber ||
                  !this.state.toNumber ||
                  !!this.state.successMessage
                }
                onClick={this.handleOnCallClick}
              >
                <CallRoundedIcon /> {configJSON.call}
              </Button>
            ) : (
              <Button
                className="button secondButton"
                data-test-id="profileButton"
              >
                <div className="profile">
                  <img src={images.image} />
                  <div className="profileName">
                    <Typography className="nameLabel">
                      {this.state.customerData.name}
                    </Typography>
                    <Typography className="timeLabel">
                      {this.state.isCallAnswered
                        ? this.formatTime(this.state.seconds)
                        : configJSON.ringing}
                    </Typography>
                  </div>
                </div>
                <div>
                  <img src={images.callClose} />
                </div>
              </Button>
            )}
            <Box className="recentCallBox">
              <Box className="recentLabelLine">
                <Typography className="recentCallsLabel">
                  {configJSON.recentCalls}
                </Typography>
                <Box className="recentLine" />
              </Box>
            </Box>
          </Box>
          {!!this.state.successMessage && (
            <Box className="centerBox">
              <Box className="callInfoBox">
                <Box className="callInfoContent">
                  <Box>
                    <img src={images.profile} className="callInfoImage" />
                    <Typography className="callInfoText">
                      {this.state.customerData.name}
                    </Typography>
                    <Typography className="callInfoText1">
                      {this.state.customerData.contactNumber}
                    </Typography>
                    <Typography className="callInfoText2">
                      {this.state.isCallAnswered
                        ? this.formatTime(this.state.seconds)
                        : configJSON.ringing}
                    </Typography>
                  </Box>
                  <Box className="callInfoButtons">
                    <IconButton
                      data-test-id="muteUnMuteIcon"
                      className="iconButton"
                      onClick={this.handleMuteUnmuteClick}
                      disabled={!this.state.isCallAnswered}
                    >
                      {this.state.isCallMute ? (
                        <img
                          src={images.activeStateMic}
                          data-test-id="muteIcon"
                        />
                      ) : (
                        <img
                          src={images.defaultStateMic}
                          data-test-id="unMuteIcon"
                        />
                      )}
                    </IconButton>
                    <IconButton
                      data-test-id="disconnectIcon"
                      className="iconButton"
                      onClick={this.handleDisconnectClick}
                      disabled={!this.state.isCallAnswered}
                    >
                      <img src={images.activeStateEndCall} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box className="callInfoBox" style={{ display: "none" }}>
                <Box className="callNotes">
                  <Typography className="cardHadingLabel">
                    {configJSON.callNotes}
                  </Typography>
                  <Button className="submitButton" disabled>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          <Box className="rightSideBox">
            <Typography className="cardHadingLabel">
              {configJSON.depositionHistory}
            </Typography>
          </Box>
          <CustomDialog
            open={this.state.showContactSelectDialog}
            title={configJSON.searchContact}
            doneTitle={configJSON.done}
            content={this.contactDialogContent()}
            showClose={false}
            rootClassName="click2callDialog"
            onClose={this.handleContactSelectDialog}
            disableSave={!this.state.selectedContact}
            onDone={this.handleContactDialogDone}
          />
        </MainContainer>
      </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainContainer = styled(Box)(() => ({
  display: "flex",
  gap: "22px",
  alignItems: "flex-start",
  "@media (max-width:796px)": {
    flexWrap: "wrap",
  },
  "& .mb-24": {
    marginBottom: "24px",
  },
  "& .mt-24": {
    marginTop: "24px",
  },
  "& .MuiTypography-colorError": {
    color: "#F87171",
  },
  "& .leftSideBox": {
    gap: "34px",
    display: "flex",
    flexDirection: "column",
    maxWidth: "330px",
    width: "100%",
    "@media (max-width:796px)": {
      maxWidth: "none",
    },
  },
  "& .header": {
    gap: "24px",
    display: "inline-flex",
    alignItems: "center",
    "& .headerTitle": {
      color: "#281D19",
      fontSize: "24px",
      fontFamily: "Poppins",
      fontWeight: 700,
      lineHeight: "32px",
    },
  },
  "& .formInput": {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& .inputLabel": {
      color: "#0F172A",
      fontSize: "20px",
      fontFamily: "Poppins",
      fontWeight: 600,
      lineHeight: "28px",
    },
    "& .inputBox": {
      "&.MuiInputBase-root": {
        background: "white",
        borderRadius: "4px",
        border: "1px #CBD5E1 solid",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "32px",
        display: "inline-flex",
        height: "48px",
        width: "100%",
        padding: "8px",
      },
      "& .MuiInputBase-input": {
        color: "#1E293B",
        fontSize: "16px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "24px",
      },
      "& .callIcon": {
        color: "#64748B",
        position: "absolute",
        left: "8px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 2,
      },
    },
  },
  "& .inputWithAddressBook": {
    display: "flex",
    alignItems: "flex-end",
    gap: "16px",
    justifyContent: "space-between",
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  "& .addressBookIcon": {
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: "4px",
    background: "#F1F5F9",
  },
  "& .button": {
    borderRadius: "8px",
    textTransform: "none",
  },
  "& .callButton.MuiButton-root": {
    background: "#34D399",
    padding: "14px",
    border: "1px solid #34D399",
    "& .MuiButton-label": {
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      display: "inline-flex",
      color: "white",
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: 700,
      lineHeight: "24px",
    },
    "&:disabled": {
      background: "#E2E8F0",
      border: "1px solid #CBD5E1",
      "& .MuiButton-label": {
        color: "#334155",
      },
    },
  },
  "& .secondButton.MuiButton-root": {
    background:
      "linear-gradient(white, white) padding-box, linear-gradient(90deg, #935AF2, #FF4956) border-box",
    border: "1px solid transparent",
    padding: "4px 13px",
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
      "& .profile": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "8px",
        "& .profileName": {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        },
        "& .nameLabel": {
          color: "#000000",
          fontSize: "16px",
          fontFamily: "Poppins",
          fontWeight: 700,
          lineHeight: "24px",
        },
        "& .timeLabel": {
          color: "#000000",
          fontSize: "13px",
          fontFamily: "Roboto",
          fontWeight: 400,
          lineHeight: "18px",
        },
      },
    },
  },
  "& .recentCallBox": {
    display: "none",
    flexDirection: "column",
    gap: "16px",
    "& .recentLabelLine": {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      "& .recentCallsLabel": {
        color: "#64748B",
        fontSize: "16px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "24px",
        whiteSpace: "nowrap",
      },
      "& .recentLine": {
        width: "100%",
        border: "0.5px solid #CBD5E1",
      },
    },
  },
  "& .centerBox": {
    maxWidth: "602px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    "@media (max-width:796px)": {
      maxWidth: "none",
    },
  },
  "& .cardHadingLabel": {
    color: "#334155",
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "26px",
  },
  "& .callInfoBox": {
    background: "#FFFFFF",
    border: "1px solid #DAD8D9",
    borderRadius: "24px",
    boxShadow: "0px 2px 8px 0px #00000014",
    width: "100%",
    textAlign: "center",
    position: "relative",
    "& .callInfoContent": {
      display: "flex",
      flexDirection: "column",
      gap: "40px",
      margin: "40px 20px",
      "& .callInfoImage": {
        width: "200px",
        height: "200px",
        borderRadius: "50%",
      },
      "& .callInfoText": {
        color: "#0F172A",
        fontSize: "30px",
        fontFamily: "Poppins",
        fontWeight: 700,
        lineHeight: "40px",
        marginTop: "10px",
      },
      "& .callInfoText1": {
        color: "#64748B",
        fontSize: "16px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "24px",
        marginTop: "10px",
      },
      "& .callInfoText2": {
        color: "#FFFFFF",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "22px",
        background: "#1E293B",
        borderRadius: "20px",
        width: "67px",
        height: "32px",
        margin: "20px auto 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .callInfoButtons": {
        gap: "12px",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        "& .iconButton": {
          width: "82px",
          height: "82px",
        },
      },
    },
    "& .callNotes": {
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "8px",
      "& .callNoteTextArea": {
        width: "100% !important",
      },
      "& .submitButton": {
        height: "40px",
        width: "100%",
        background: "#0099FF",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "Poppins",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "none",
      },
    },
  },
  "& .rightSideBox": {
    maxWidth: "375px",
    width: "100%",
    height: "762px",
    background: "#FFFFFF",
    border: "1px solid #DAD8D9",
    borderRadius: "24px",
    boxShadow: "0px 2px 8px 0px #00000014",
    padding: "20px",
    display: "none",
    flexDirection: "column",
    gap: "8px",
    "@media (max-width:796px)": {
      maxWidth: "none",
    },
  },
}));
// Customizable Area End
