import React, { memo, useCallback, useState } from "react";
import {
  // Customizable Area Start
  Typography,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  TableFooter,
  TablePagination,
  styled,
  Tooltip,
  InputBase,
  InputAdornment,
  Checkbox,
  ClickAwayListener,
  // Customizable Area End
} from "@material-ui/core";
import AgentsController, {
  Agent,
  Props, configJSON
} from "./AgentsController";

// Customizable Area Start
import Layout from "../../../components/src/Layout.web";
import { clipboards, group, noResultFound } from "./assets";
import { withStyles } from "@material-ui/styles";
import EditAgent from "./EditAgent.web";
import Loader from "../../../components/src/Loader.web";
import { MoreVert } from "@material-ui/icons";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteConfirmationDialog from "../../../components/src/DeleteConfirmationDialog.web";

// Customizable Area End

export default class Agents extends AgentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNoData() {
    return (
      <Box style={webStyle.dataContainer} data-test-id="no-data">
        <Box style={webStyle.noDataInnerContainer}>
          <Image src={clipboards}/>
          <Typography style={webStyle.noDataMainText}>{configJSON.noDataMainText}</Typography>
          <Typography style={webStyle.noDataSubText}>{configJSON.noDataSubText}</Typography>
        </Box>

      </Box>
    )
  }

  renderTable() {
    return (
      <CustomTable 
        data-test-id="customTable"
        agentsData={this.state.agentsData}
        handleDeleteConfirmationDialog={this.handleDeleteConfirmationDialog}
        handleEditAgent={this.handleEditAgent}
        handlePageChange={this.handlePageChange}
        handleRowPerPageChange={this.handleRowPerPageChange}
        handleSelect={this.handleSelect}
        perPage={this.state.perPage}
        selectedMode={this.state.selectedMode}
        updateSelectedData={this.updateSelectedData}
      />
    )
  }
  renderview()
  {
    return (
      <>
      {
        this.state.agentsData.agents.data.length === 0  ? this.renderNoDataView() : this.renderTable()
      } 
      </>
    )
  }

  renderNoDataView(){
    return(
      <>
        {
          this.state.agentsData.search? this.renderNoSearchData() :
          this.renderNoData()
        }
      </>
    )
  }

  renderNoSearchData() {
    return (
      <Box style={webStyle.dataContainer} data-test-id="no-search-data">
        <Box style={webStyle.noDataInnerContainer}>
          <Image src={noResultFound}  style={{marginBottom:0,height:"220px"}}/>
          <Typography style={webStyle.noSearchMainText}>No Result found</Typography>
          <Typography style={webStyle.noSearchSubText}>No Agent matched your Criteria. Try Searching for something else.</Typography>
        </Box>
      </Box>
    )
  }
  
  renderList() {
    return (
     
      <Layout menuItem="agent" navigation={this.props.navigation}>
        <Box display="grid" gridGap={20}>
      <Box>

        <Box style={{...webStyle.header,background: this.state.selectedMode ? 'white' : 'transparent', padding: '5px' }}>

          <Box display="inline-flex" alignItems="center">
            <img src={group}
              width={64}
              height={48}
            />
            <Typography style={webStyle.headerTitle}>{configJSON.listAgent}</Typography>
          </Box>
          <InputBox
            placeholder="Search"
            type="text"
            data-test-id={"searchtxt"}
            startAdornment={(
              <InputAdornment position="start" style={{ zIndex: 1, marginRight: 0 }}>
                <SearchRoundedIcon className="search" />
              </InputAdornment>
            )}
            onChange={(event: { target: { value: string; }; }) => this.handleSearch(event.target.value)}
          ></InputBox>
          {this.state.selectedMode?
            <Box style={{display:'flex'}}>
            <Box style={{ display: 'flex',fontFamily: "Poppins", cursor: 'pointer', alignItems: 'center' }}>
            <StyledCheckbox
              data-test-id={"select_all"}
              checked={this.state.selectAll}
              onChange={(event,checked)=>this.handleSelectAll(!checked)}
            ></StyledCheckbox>
            <Box data-test-id={"select_all_text"} 
              onClick={()=>this.handleSelectAll(this.state.selectAll)}
              style={{
                fontWeight: 700,
                color: 'rgba(100, 116, 139, 1)',
                fontSize: "16px",
                cursor:'pointer',
                paddingLeft:"7px"
              }}
            >
              {!this.state.selectAll?"Select all":"Unselect all"}
            </Box>
          </Box>
          {this.state.selectedId.length>0?
          <Box data-test-id="select_delete" 
          onClick={()=>this.handleBulkDeleteConfirmationDialog()} 
          style={{ display: 'flex', marginLeft:'20px', fontFamily: "Poppins", cursor: 'pointer', alignItems: 'center' }}>

            <DeleteOutlineRoundedIcon style={{ color: 'rgba(220, 38, 38, 1)', marginRight: '5px' }}></DeleteOutlineRoundedIcon>
            <Box
              style={{
                fontWeight: 700,
                color: 'rgba(220, 38, 38, 1)',
                fontSize: "16px",
              }}
            >
              Delete
            </Box>
          </Box>:null}
          
          
          <Box data-test-id="cancel_all"style={{
            display: 'flex', fontFamily: "Poppins", cursor: 'pointer',
            fontWeight: 700,marginLeft:'20px', 
            
            color: 'rgba(217, 119, 6, 1)',
            fontSize: "16px",
          }}
           onClick={()=> this.handleSelect()}
           >
            Cancel
          </Box>
      </Box>:null}
     
          <Button
            disabled={this.state.selectedMode}
            data-test-id="add-agent"
            variant="contained"
            style={{...webStyle.headerButton,background:this.state.selectedMode?"rgba(241, 245, 249, 1)":"#0099FF",color:this.state.selectedMode?"rgba(100, 116, 139, 1)":"white"}}
            startIcon={ <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>}
            onClick={this.handleAddAgent}
          >
             {configJSON.addAgent}
          </Button>
</Box>
       
      </Box>
      {this.state.isLoading ? (<Loader loading={this.state.isLoading} />) : this.renderview()}
      </Box>
      <DeleteConfirmationDialog 
        open={this.state.bulkDeleteConfirmation}
        onDone={this.handleBulkDelete}
        onClose={this.handleBulkDeleteConfirmationDialog}
      />
      <DeleteConfirmationDialog 
        open={!!this.state.deleteConfirmationId}
        onDone={() => this.handleDeleteAgent(this.state.deleteConfirmationId)}
        onClose={() => this.handleDeleteConfirmationDialog("")}
      />
    </Layout>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        this.renderList()
      // Customizable Area End
    );
  }
}

// Customizable Area Start


export const InputBox = styled(InputBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center', // Vertically center the content
  '&.Mui-error': {
    '& .MuiInputBase-input': {
      borderColor: '#F87171',
    },
  },
  '& .MuiInputBase-input': {
    borderColor: '#CBD5E1',
    fontSize: '16px',
    width: '100%',
    marginRight: '20px',
    marginBottom: '2px',
    borderRadius: '50px',
    paddingLeft: '40px', 
    paddingRight: '10px',
    height: '30px', 
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: '1px solid',
  },
  '& .search': {
    position: 'absolute',
    left: '14px',
    top: '50%',
    color: '#94A3B8',
    transform: 'translateY(-50%)',
    zIndex: 2, // Ensure the icon appears in front
  },
}));
const Image = styled("img")(({theme}) => ({
    marginBottom: 30,
    width: 238,
    height: 232,
}))
export const StyledCheckbox = styled(Checkbox)({
  padding: "0",
  "&..MuiCheckbox-root":
  {
    border: "1px solid #9e9e9e", // Set border style for the checkbox
    borderRadius: "1px",
  },
  "&.MuiCheckbox-colorSecondary.Mui-checked": {
    color: "#0099FF",
  },
});
export const CustomTooltip = withStyles({
  tooltip: {
    width: 182,
    display: "flex",
    flexDirection: "column",
    rowGap: 2,
    marginTop:20,
    marginRight:-2,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderStyle: "solid",
    borderRadius: 8,
    boxShadow: "0px 4px 8px 0px #00000008",
  },
})(Tooltip)
export const ActionButton = styled(IconButton)(() => ({
  padding: 8,
  "&:before": {
    content: '""',
    position: "absolute",
    zIndex: 1,
    width: "calc(100%)",
    height: "calc(100%)",
    opacity: 0,
    borderRadius: "50%",
  },
  "&:focus": {
    opacity: 1,
    "&:before": {
      opacity: 1,
    },
  },
}))
const webStyle = {
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    // justifyContent: "space-between",
    alignItems: "center",
    gap: 24,
  },
  headerTitle: {
    fontWeight: 700,
    fontSize: 24,
    marginLeft: 24,
    marginRight: 24,
  },
  dataContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 8,
  },
  headerButton: {
    fontWeight: 700,
    fontSize: 16,
    textTransform: "none",
    color: "white",
    marginLeft: 'auto',
    backgroundColor: "#0099FF",
    borderRadius: 8,
    padding: "10px 16px",
  },
  tableHeader: {
    fontWeight: 700,
    fontSize: 14,
    maxWidth: "150px"
  },
  noDataInnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: 10,
    margin: 20,
  },
  tableRow: {
    fontWeight: 400,
    fontSize: 14,
    maxWidth: "150px",
    wordBreak: "break-all",
  },


  noDataMainText: {
    fontWeight: 700,
    fontSize: 16,
  },
  noSearchSubText: {

    fontSize: 16,
  },
  noSearchMainText: {
    fontWeight: 700,
    fontSize: 25,
  },

  tooltipButton: {
    textTransform: "none",
    justifyContent: "start",
    fontSize: 14,
    fontWeight: 400,
  },
  noDataSubText: {
    fontSize: 14,
  },
  redText: {
    color: "red",
  },
} as const;

interface CustomTableProps {
  agentsData: {
    agents: {
      data: Array<Agent>;
    };
    total_agents: number;
    current_page: number;
  };
  selectedMode: boolean;
  perPage: number;
  updateSelectedData: (id: string) => void;
  handleEditAgent: (row: Agent) => void;
  handleSelect: () => void;
  handleDeleteConfirmationDialog: (id: string) => void;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleRowPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomTable: React.FC<CustomTableProps> = memo((props) => {
  const {
    agentsData,
    selectedMode,
    handleEditAgent,
    handleSelect,
    handleDeleteConfirmationDialog,
    handlePageChange,
    handleRowPerPageChange,
  } = props;
  const [openId, setOpenId] = useState<string | null>(null);

  const handleToggleAction = useCallback((toggleId: string) => {
    setOpenId((prevOpenId) => (prevOpenId === toggleId ? null : toggleId));
  }, []);

  const handleCloseAction = useCallback(() => {
    setOpenId(null);
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {selectedMode ? <TableCell>{""}</TableCell> : null}
            <TableCell style={webStyle.tableHeader}>{configJSON.agentName}</TableCell>
            <TableCell style={webStyle.tableHeader}>{configJSON.userName}</TableCell>
            <TableCell style={webStyle.tableHeader}>{"Email Id"}</TableCell>
            <TableCell style={webStyle.tableHeader}>{configJSON.departments}</TableCell>
            <TableCell style={webStyle.tableHeader}>{configJSON.extensions}</TableCell>
            <TableCell style={webStyle.tableHeader}>{configJSON.actions}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agentsData.agents.data.map((agents, index) => (
            <TableRow key={agents.id}>
              {selectedMode ? (
                <TableCell component="th" scope="row">
                  <StyledCheckbox
                    onClick={() => props.updateSelectedData(agents.id)}
                    data-test-id={`checkbox-delete-${index}`}
                    checked={agents.attributes.isselected}
                  />
                </TableCell>
              ) : null}
              <TableCell component="th" scope="row">
                {agents.attributes.agent_name}
              </TableCell>
              <TableCell style={webStyle.tableRow}>
                {agents.attributes.username}
              </TableCell>
              <TableCell style={webStyle.tableRow}>
                {agents.attributes.email}
              </TableCell>
              <TableCell style={webStyle.tableRow}>
                {agents.attributes.departments && agents.attributes.departments.length
                  ? agents.attributes.departments.join(", ")
                  : "-"}
              </TableCell>
              <TableCell style={webStyle.tableRow}>
                {agents.attributes.extension}
              </TableCell>
              <TableCell style={webStyle.tableRow}>
                <ClickAwayListener
                  mouseEvent={openId === agents.id ? "onClick" : false}
                  onClickAway={handleCloseAction}
                >
                  <CustomTooltip
                    interactive
                    open={agents.id === openId}
                    placement="left-start"
                    title={
                      <>
                        <Button
                          data-test-id="edit-agent"
                          style={webStyle.tooltipButton}
                          onClick={() => handleEditAgent(agents)}
                        >
                          Edit Agent
                        </Button>
                        <Button
                          data-test-id="select-agent"
                          style={webStyle.tooltipButton}
                          onClick={() => handleSelect()}
                        >
                          Select
                        </Button>
                        <Button
                          data-test-id="delete-agent"
                          style={{...webStyle.tooltipButton,...webStyle.redText}}
                          onClick={() => handleDeleteConfirmationDialog(agents.id)}
                        >
                          Delete
                        </Button>
                      </>
                    }
                  >
                    <ActionButton
                      name={agents.id.toString()}
                      size="small"
                      onClick={() => handleToggleAction(agents.id)}
                      style={{background:agents.id === openId?'linear-gradient(90deg, #1676C0 0%, #13A9EC 100%)':"white"}}
                      data-test-id={`action-${index}`}
                    >
                      <MoreVert
                        fontSize="inherit"
                        style={{zIndex: 2,color:agents.id === openId?"white":"rgba(0, 0, 0, 0.54)"}}
                      />
                    </ActionButton>
                  </CustomTooltip>
                </ClickAwayListener>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={6}
              count={agentsData.total_agents}
              rowsPerPage={props.perPage}
              page={agentsData.current_page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
});
// Customizable Area End
